<template>
    <div>
        <Header></Header>
        <div class="container-fluid">
            <div class="row">
                <h2 class="col-xl-4 offset-xl-4 text-center">Ihre Buchung bei<br> waschprofis</h2>
            </div>
        </div>
        <CartDetails v-if="product != 'coupon'"></CartDetails>
        <CouponCartDetails v-if="product == 'coupon'"></CouponCartDetails>
        <div class="container-fluid grey-bg">
            <div class="row">
                <div class="col-md-4 text-right">
                    <h3>Rechnungsadresse</h3>
                </div>
                <div class="col-lg-6 offset-lg-1">
                    <form>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="customer_type" id="customerType1" value="privat" v-model="user_data.customer_type">
                          <label class="form-check-label" for="customerType1">Privatkunde</label>
                        </div>
                        <div class="form-check form-check-inline bottom-margin">
                          <input class="form-check-input" type="radio" name="customer_type" id="customerType2" value="company" v-model="user_data.customer_type">
                          <label class="form-check-label" for="customerType2">Firmenkunde</label>
                        </div>
                        <div class="form-group" :class="{ 'form-group--error': $v.user_data.company.$error }" v-if="user_data.customer_type == 'company'">
                            <input type="text" class="form-control" :class="{ 'is-invalid': $v.user_data.company.$error }" id="company" placeholder="Firma" v-model.trim="$v.user_data.company.$model" @blur="$v.user_data.company.$touch()" required>
                            <div class="error" v-if="!$v.user_data.company.required">Bitte Firmennamen angeben</div>
                        </div>
                        <div class="form-group" :class="{ 'form-group--error': $v.user_data.company_tax_number.$error }" v-if="user_data.customer_type == 'company'">
                            <input type="text" class="form-control" :class="{ 'is-invalid': $v.user_data.company_tax_number.$error }" id="company_tax_number" placeholder="USt.-Nr." v-model.trim="$v.user_data.company_tax_number.$model" @blur="$v.user_data.company_tax_number.$touch()" required>
                            <div class="error" v-if="!$v.user_data.company_tax_number.required">Bitte gültige Umsatzsteuer Nummer angeben</div>
                        </div>
                        <div class="form-group">
                            <select class="form-control" id="title" v-model.trim="user_data.title">
                                <option selected value="">Anrede</option>
                                <option selected value="Frau">Frau</option>
                                <option selected value="Herr">Herr</option>
                            </select>
                        </div>
                        <div class="form-group" :class="{ 'form-group--error': $v.user_data.lastname.$error }">
                            <input type="text" class="form-control" :class="{ 'is-invalid': $v.user_data.lastname.$error }" id="lastname" placeholder="Nachname" v-model.trim="$v.user_data.lastname.$model" @blur="$v.user_data.lastname.$touch()" required>
                            <div class="error" v-if="!$v.user_data.lastname.required">Bitte Nachnamen angeben</div>
                        </div>
                        <div class="form-group" :class="{ 'form-group--error': $v.user_data.firstname.$error }">
                            <input type="text" class="form-control" :class="{ 'is-invalid': $v.user_data.firstname.$error }" id="firstname" placeholder="Vorname"  v-model.trim="$v.user_data.firstname.$model"  @blur="$v.user_data.firstname.$touch()" required>
                            <div class="error" v-if="!$v.user_data.firstname.required">Bitte Vornamen angeben</div>
                        </div>
                        <div class="form-group" :class="{ 'form-group--error': $v.user_data.street.$error }">
                            <input type="text" class="form-control" :class="{ 'is-invalid': $v.user_data.street.$error }" id="street" placeholder="Straße/Nr." v-model.trim="$v.user_data.street.$model"  @blur="$v.user_data.street.$touch()" required>
                            <div class="error" v-if="!$v.user_data.street.required">Bitte Straße angeben</div>
                        </div>
                        <div class="form-group" :class="{ 'form-group--error': $v.user_data.zipcode.$error }">
                            <input type="text" class="form-control" :class="{ 'is-invalid': $v.user_data.zipcode.$error }" id="zipcode" placeholder="PLZ" v-model.trim="$v.user_data.zipcode.$model"  @blur="$v.user_data.zipcode.$touch()" required>
                            <div class="error" v-if="!$v.user_data.zipcode.required">Bitte Postleitzahl angeben</div>
                            <div class="error" v-if="!$v.user_data.zipcode.numeric">Bitte gültige Postleitzahl angeben</div>
                            <div class="error" v-if="!$v.user_data.zipcode.minLength">Bitte gültige Postleitzahl angeben</div>
                            <div class="error" v-if="!$v.user_data.zipcode.maxLength">Bitte gültige Postleitzahl angeben</div>
                        </div>
                        <div class="form-group" :class="{ 'form-group--error': $v.user_data.city.$error }">
                            <input type="text" class="form-control" :class="{ 'is-invalid': $v.user_data.city.$error }" id="city" placeholder="Ort" v-model.trim="$v.user_data.city.$model"  @blur="$v.user_data.city.$touch()" required>
                            <div class="error" v-if="!$v.user_data.city.required">Bitte Ort angeben</div>
                        </div>
                        <div class="input-group form-group" :class="{ 'form-group--error': $v.user_data.phone.$error }">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">+49</span>
                            </div>
                            <input type="text" class="form-control" :class="{ 'is-invalid': $v.user_data.phone.$error }" id="phone" placeholder="Telefon" v-model.trim="$v.user_data.phone.$model"  @blur="$v.user_data.phone.$touch()" required>
                        </div>
                        <div class="form-group" :class="{ 'form-group--error': $v.user_data.phone.$error }">
                            <div class="error" v-if="!$v.user_data.phone.required">Bitte Telefonnummer angeben</div>
                            <div class="error" v-if="!$v.user_data.phone.numeric">Bitte gültige Telefonnummer angeben</div>
                        </div>
                        <div class="form-group bottom-button-margin" :class="{ 'form-group--error': $v.user_data.mail.$error }">
                            <input type="text" class="form-control" :class="{ 'is-invalid': $v.user_data.mail.$error }" id="mail" placeholder="E-Mail" v-model.trim="$v.user_data.mail.$model"  @blur="$v.user_data.mail.$touch()" required>
                            <div class="error" v-if="!$v.user_data.mail.required">Bitte E-Mail Adresse angeben</div>
                            <div class="error" v-if="!$v.user_data.mail.email">Bitte eine gültige E-Mail Adresse angeben</div>
                        </div>
                        <div class="form-group">
                            <a @click="addUserData()" class="button invert">Zur Kasse</a>
                        </div>
                        <p  v-if="product != 'coupon'">Bei der Buchung erfolgt eine Anzahlung in Höhe von 39,90 € vom Gesamtbetrag. Die Restsumme ({{ total }}) wird bei Abholung ihres Fahrzeugs fällig. Sie können ihre Buchung kostenlos bis zu 24 Stunden vor Terminbeginn unter <a href="mailto:storno@waschprofis.de">storno@waschprofis.de</a> stornieren.</p>
                    </form>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
    import CartDetails from "@/components/CartDetails.vue";
    import CouponCartDetails from "@/components/CouponCartDetails.vue";

    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";

    import { required, requiredIf, email, numeric, minLength, maxLength } from 'vuelidate/lib/validators'

    export default {
        name: 'BookingForm',

        components: {
            CartDetails,
            CouponCartDetails,
            Header,
            Footer
        },
        data() {
            return {
                user_data: this.$store.state.user_data.user_data,
                product: this.$store.state.cart.product,
                car_type: this.$store.state.cart.product.split("_")[0],
                cleaning_type: this.$store.state.cart.product.split("_")[1],
                additional_service: this.$store.state.cart.additional_service,
                cart: this.$store.state.cart,
                actionWeek: false
            }
        },
        watch: {
            phoneNumber(newVal){
                if(newVal.startsWith("0")){
                    this.user_data.phone = newVal.slice(1);
                }else if(newVal.startsWith("+49")){
                    this.user_data.phone = newVal.slice(3);
                }
            }
        },
        methods: {
            addUserData() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.submitStatus = 'ERROR'
                } else {
                    this.$store.commit({
                        type: 'addToUserData',
                        user_data: this.user_data,
                    }),
                    this.$router.push({name: 'CheckOut'})
                }
            },
        },
        computed: {
          items() { return this.$store.state.items },
          phoneNumber() {
            return this.user_data.phone
          },
          total(){
              var cleaning = this.items[this.car_type][this.cleaning_type].price,
                  additional_service = this.items[this.car_type][this.cleaning_type].additional_service,
                  additional = 0,
                  hasCaramic = false;

              if(this.additional_service.length > 0){
                  this.additional_service.forEach(value=>{
                      additional += additional_service[value].price;
                      if(value === "Keramikversiegelung"){
                            hasCaramic = true;
                          }
                  })
              }

              if(this.actionWeek && this.cleaning_type === "outside" && hasCaramic){
                var price = parseFloat(cleaning + additional);
                price = (price * 75 ) / 100;
                return `${parseFloat(price - 39.90).toFixed(2)} €`
              }else{
                return `${parseFloat(cleaning + additional - 39.90).toFixed(2)} €`;
              }
          },
        },
        validations () {
            return {
                user_data: {
                    company: {
                        required: requiredIf(() => {
                            if('user_data' in this && this.user_data.customer_type == 'company'){
                                return true;
                            }
                        })
                    },
                    company_tax_number: {
                        required: requiredIf(() => {
                            if('user_data' in this && this.user_data.customer_type == 'company'){
                                return true;
                            }
                        })
                    },
                    lastname: {
                        required
                    },
                    firstname: {
                      required,
                    },
                    street: {
                      required,
                    },
                    zipcode: {
                      required,
                      numeric,
                      minLength: minLength(5),
                      maxLength: maxLength(5),
                    },
                    city: {
                      required,
                    },
                    phone: {
                      required,
                      numeric
                    },
                    mail: {
                      required,
                      email
                    }
                },
            }
        },
        mounted() {
            if(new Date() < new Date('August 15, 2021 23:59:59')){
                this.actionWeek = true;
            }
        }
    }
</script>
<style lang="scss" scoped>

    form{
        .form-control,
        .form-check{
            height: 65px;
            border: none;

            &.is-invalid{
                border: thin solid #dc3545;
            }
        }

        .form-group--error .error {
            margin-top: 10px;
            display: block;
            color: #dc3545;
        }
        .error {
            font-size: 0.85rem;
            line-height: 1;
            display: none;
        }

        .form-check{
            margin-right: 75px;

            label{
                font-size: 21px;
            }
        }

        .bottom-margin{
            margin-bottom:30px;
        }

        .bottom-button-margin{
            margin-bottom:50px;
        }
    }


</styles>